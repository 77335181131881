//Librerías
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { userActions } from '../../acciones/user-actions';
import { Login } from '../Login/Login';

class Logout extends Component {
    componentDidMount() {
        this.props.dispatch(userActions.logout())
        window.location.reload(true)
    }
    render() {
        return <div>
            <Login />
        </div>
    }
}
function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}
const connectedLogout = connect(mapStateToProps)(Logout);
export { connectedLogout as Logout }