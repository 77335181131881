//Librerías
import React, { Component } from 'react'
import { Route, BrowserRouter as Router } from 'react-router-dom';

//Elemento de trendmicro
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import ClickOutside from 'react-click-outside';

//Componentes
import { ListadoLocales } from '../Locales/ListadoLocales'
import { PrivateRoute } from '../PrivateRoute/PrivateRoute'
import { LocalEstatus } from '../LocalesEstatus/LocalEstatus'
import { ListaVentaDia } from '../VentaDia/ListaVentaDia'
import { ListadoTickets } from '../ListadoTickets/ListadoTickets'
import { ListaMezclaProductos } from '../MezclaProductos/ListadoMezclaProductos'
import { Logout } from '../Logout/Logout';

//Estilos
import MaterialIcon from 'material-icons-react'
import './SideNavMenu.css'

class SideNavMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
    }
    render() {
        return (
            <Router >
                <Route render={({ location, history }) => (
                    <div>
                        <ClickOutside
                            onClickOutside={() => {
                                this.setState({ expanded: false });
                            }}
                        >
                            <SideNav
                                expanded={this.state.expanded}
                                onToggle={(expanded) => {
                                    this.setState({ expanded });
                                }}
                                onSelect={(selected) => {
                                    const to = '/' + selected;
                                    console.log(to)
                                    if (location.pathname !== to) {
                                        history.push(to);
                                    }
                                }}
                                className="sidenav-back"
                            >
                                <SideNav.Toggle className="toggle-back" />
                                <SideNav.Nav >
                                    <NavItem eventKey="estatus" className="item-tam">
                                        <NavIcon>
                                            <MaterialIcon icon="monetization_on"></MaterialIcon>
                                        </NavIcon>
                                        <NavText >CONCENTRADO DIARIO</NavText>
                                    </NavItem>
                                    <NavItem eventKey="tickets" className="item-tam">
                                        <NavIcon>
                                            <MaterialIcon icon="style"></MaterialIcon>
                                        </NavIcon>
                                        <NavText>TICKETS</NavText>
                                    </NavItem>
                                    <NavItem eventKey="productos" className="item-tam">
                                        <NavIcon>
                                            <MaterialIcon icon="fastfood"></MaterialIcon>
                                        </NavIcon>
                                        <NavText>PRODUCTOS</NavText>
                                    </NavItem>
                                    <NavItem eventKey="registro" className="item-tam">
                                        <NavIcon>
                                            <MaterialIcon icon="storefront"></MaterialIcon>
                                        </NavIcon>
                                        <NavText>ADMINISTRACIÓN DE LOCALES</NavText>
                                    </NavItem>
                                    <NavItem eventKey="logout" className="item-tam">
                                        <NavIcon>
                                            <MaterialIcon icon="power_settings_new"></MaterialIcon>
                                        </NavIcon>
                                        <NavText>CERRAR SESIÓN</NavText>
                                    </NavItem>
                                </SideNav.Nav>
                            </SideNav>
                        </ClickOutside>
                        <div>
                            <main>
                                <PrivateRoute exact path="/estatus" component={LocalEstatus} />
                                <PrivateRoute exact path="/ventaDia" component={ListaVentaDia} />
                                <PrivateRoute exact path="/registro" component={ListadoLocales} />
                                <PrivateRoute exact path="/tickets" component={ListadoTickets} />
                                <PrivateRoute exact path="/productos" component={ListaMezclaProductos} />
                                <PrivateRoute exact path="/logout" component={Logout} />
                                {/*<Route path="/ventaDia" component={ListaVentaDia} />*/}
                            </main>
                        </div>
                    </div>
                )}
                />
            </Router>
        );
    }
}

export default SideNavMenu;
