import React, { Component } from 'react';
import axios from 'axios'
import { KRAKEN_URL } from '../../constants'

import "./ModalConfirmDelete.css"
import MaterialIcon from 'material-icons-react'
import Modal from 'react-bootstrap/Modal'
import { /*ToastContainer,*/ toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class ModalConfirmDelete extends Component {

    deleteLocal(localId, e) {        
        e.preventDefault();
            axios.post(KRAKEN_URL + 'locales/deleteLocal',
                {
                    "localId": localId
                }
            ).then((res) => {
                if (!res.data.result.success) {
                    this.setState({
                        status: false,
                    });
                    toast.error(res.data.result.mensaje)
                }else{
                    this.setState({
                        status: true,
                    });
                    toast.success(res.data.result.message);
                    setTimeout(function(){
                        window.location.reload(true);
                    }, 3000);
                }
            }).catch((err) => {
                console.log(err)
            });
    }

    render(){
        return ( 
            <section id="modalConfirmDelete" className="text-center">
                <Modal
                    {...this.props}
                    size="sm"
                    centered
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton className="header-delete">
                        <h6>Borrar local</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="">
                            <MaterialIcon icon="delete_outline" className="material-icons iconAlign"></MaterialIcon>
                            &nbsp;¿Está seguro que desea borrar el local <b>'{this.props.children.localAlias}'</b> ?
                        </p>
                    </Modal.Body>
                    <Modal.Footer> 
                    <button className="btn accionEstatus" onClick={e => this.deleteLocal(this.props.children.localId, e)}>
                            <MaterialIcon icon="done" className="material-icons iconAlign"></MaterialIcon>
                            &nbsp;Aceptar
                        </button>
                        <button className="btn accionVolver" onClick={this.props.onHide}>
                            <MaterialIcon icon="clear" className="material-icons iconAlign"></MaterialIcon>
                            &nbsp;Cancelar
                        </button>
                    </Modal.Footer>
                </Modal>
            </section>
        )
    }
}
export default ModalConfirmDelete;