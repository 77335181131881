//Librerías
import React, { Component } from 'react'
import axios from 'axios'
import { KRAKEN_URL, API_URL } from '../../constants';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
//import { NavLink } from 'react-router-dom'

//Estilo
import './LocalEstatus.css'
import MaterialIcon from 'material-icons-react'

//Elementos bootstrap
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Moment from 'moment';
//import Navbar from 'react-bootstrap/Navbar'

//Imágenes
//import logo from '../img/cheffsysLOGO.svg'

//Componentes
//import LocalesChart from './LocalesChart'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

class LocalEstatus extends Component {
    constructor(props) {
        super(props);
        var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        var f = new Date();
        this.state = {
            jwt: this.props.user,
            locales: [],
            localesId: [],
            listHDiaFinal: [],
            localesRegistros: [],
            ventasLocales: [],
            local: [],
            status: '',
            //fechaHoy: meses[f.getMonth()] + " " + f.getDate() + " del " + f.getFullYear(),
            fechaHoy: f.getDate() + " de " + meses[f.getMonth()] + " del " + f.getFullYear(),
            request: true,
            error: ''
        };
        this.handleSizePerPage = this.handleSizePerPage.bind(this);
        this.getInitialMount();
    }

    /*componentDidMount() {
        this.getInitialMount();
    }*/

    componentDidUpdate(prevProps) {
        console.log("prevProps: " + prevProps.user)
        console.log("actualProps: " + this.props.user)
        console.log(this.props.user !== prevProps.user)
        if (this.props.user !== prevProps.user) {
            console.log("Refreshing data...")
            this.getInitialMount();
        }
    }

    getInitialMount() {
        console.log("Getting the initial data...")
        let authKooben = this.state.jwt;
        let base64url = authKooben.split('.')[1];
        let decKooben = JSON.parse(window.atob(base64url));
        let hoy = new Date();
        let formatoFecha = hoy.getFullYear() + "-0" + (hoy.getMonth() + 1) + "-" + hoy.getDate();
        console.log(authKooben)
        console.log(decKooben.alias)
        this.getLocalesLinea(decKooben.lineaId);
        //this.getListHDiaFinal("2019-08-19");
        this.getListHDiaFinal(formatoFecha);
    }

    handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    getLocalesLinea(lineaId) {
        console.log(lineaId)
        axios.post(
            KRAKEN_URL + 'locales/list',
            {
                "lineaId": lineaId
            },
            {
                headers: { 'Authorization': 'Bearer ' + this.state.jwt }
            }
        ).then((res) => {
            const localesObtenidos = [];
            if (res.data.result.success) {
                res.data.locales.forEach(local => {
                    localesObtenidos.push(local.localId);
                });
                this.setState({
                    localesId: localesObtenidos,
                    locales: res.data.locales,
                    request: false
                })
                this.getLocalesRegistro();
            } else {
                this.setState({
                    request: false
                })
            }
        }).catch((err) => {
            this.setState({
                error: err,
                request: false
            })
        })
    }

    getLocalesRegistro() {
        axios.post(
            API_URL + 'listRegistros',
            {
                "idLocales": this.state.localesId
            },
            {
                headers: { 'Authorization': 'Bearer ' + this.state.jwt }
            }
        ).then((res) => {
            this.setState({
                localesRegistros: res.data.list,
                request: false
            })
            this.getInfo();
        }).catch((err) => {
            this.setState({
                error: err,
                request: false
            });
        })
    }

    getListHDiaFinal(fecha) {
        axios.post(
            API_URL + 'listVentasDia',
            {
                "fecha": fecha
            },
            {
                headers: { 'Authorization': 'Bearer ' + this.state.jwt }
            }
        ).then((res) => {
            if (res.data.result.success) {
                this.setState({
                    listHDiaFinal: res.data.listaHdiaFinal,
                    request: false
                })
            } else {
                this.setState({
                    request: false
                })
            }
        }).catch((err) => {
            this.setState({
                error: err,
                request: false
            });
        })
    }

    getInfo() {
        const localesActualizados = this.state.listHDiaFinal;
        const localesSinActualizar = this.state.locales;
        const info = [];

        for (var i = 0; i < localesSinActualizar.length; i++) {
            let igual = false;
            for (var j = 0; j < localesActualizados.length; j++) {
                if ((localesSinActualizar[i]['localId'] === localesActualizados[j]['id']['idLocal'])) {
                    igual = true;
                    info.push({
                        localId: localesActualizados[j]['id']['idLocal'],
                        localAlias: localesSinActualizar[i]['localAlias'],
                        fecha: localesActualizados[j]['id']['fecha'],
                        ventaTotal: localesActualizados[j]['vtotal'],
                        ventaNeta: localesActualizados[j]['vneta'],
                        ventaBruta: localesActualizados[j]['vbruta'],
                        estatus: 1
                    })
                }
            }
            if (!igual) {
                info.push({
                    localId: localesSinActualizar[i]['localId'],
                    localAlias: localesSinActualizar[i]['localAlias'],
                    fecha: "",
                    ventaTotal: 0,
                    ventaNeta: 0,
                    ventaBruta: 0,
                    estatus: 0
                })
            }
        }
        this.setState({
            ventasLocales: info
        })
    }

    mostrarRegistros = () => {
        const localesUltimosRegistros = this.state.localesRegistros;
        console.log("fechas uregistro: ", localesUltimosRegistros);
        const info = this.state.ventasLocales;
        const infoFinal = [];

        for (var i = 0; i < localesUltimosRegistros.length; i++) {
            for (var j = 0; j < info.length; j++) {
                if ((localesUltimosRegistros[i]['idLocal'] === info[j]['localId'])) {
                    infoFinal.push({
                        localId: info[j]['localId'],
                        localAlias: info[j]['localAlias'],
                        fecha: info[j]['fecha'],
                        estatus: info[j]['estatus'],
                        ventaBruta: info[j]['ventaBruta'],
                        ventaNeta: info[j]['ventaNeta'],
                        ventaTotal: info[j]['ventaTotal'],
                        //horaRegistro: localesUltimosRegistros[i]['horaRegistro']
                        horaRegistro: localesUltimosRegistros[i]['horaRegistro'] !== "Sin registros" 
                                        ? Moment(localesUltimosRegistros[i]['horaRegistro']).format('DD/MM/YYYY, h:mm:ss a') 
                                        : "sin registros"

                    })
                }
            }
        }

        if (this.state.error) {
            console.log(this.state.error);
            return (
                <div className="background-body font-body text-center cardCentrado">
                    <Alert variant="danger">
                        <Alert.Heading>¡Ha ocurrido un error inesperado!</Alert.Heading>
                        <p>{/* */}</p><hr />
                        <p className="mb-0"><Alert.Link href="./Login/Login.jsx">Volver a Inicio</Alert.Link></p>
                    </Alert>
                </div>
            );
        } else {
            const columns = [{
                dataField: 'localAlias',
                text: 'Local'
            }, {
                dataField: 'horaRegistro',
                text: 'Último registro'
            }, {
                dataField: 'estatus',
                text: 'Estatus',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return row.estatus === 1 ? <Button variant="light" className="btn-done-back" size="sm">
                        <MaterialIcon icon="cloud_done" className="material-icons"></MaterialIcon> </Button>
                        : <Button variant="light" className="btn-fail-back" size="sm">
                            <MaterialIcon icon="cloud_done" className="material-icons"></MaterialIcon>
                        </Button>
                }
            }, {
                dataField: 'acciones',
                isDummyField: true,
                text: 'Ver local',
                formatter: (cellContent, row) => {
                    return <Button variant="light" className="btn-send-back" size="sm"
                        onClick={(event) => this.openVentaDia(row, event)}>
                        <MaterialIcon icon="send" className="material-icons"></MaterialIcon>
                    </Button>
                }
            }];

            function indication() {
                return infoFinal.length ? "No hay registros que mostrar" : "Cargando...";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size} locales
                </span>
            );

            const options = {
                custom: true,
                totalSize: infoFinal.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form>
                            <fieldset className="fieldsetEstatus">
                                <Form.Row>
                                    <div className="d-flex flex-row">
                                        <Form.Label column>Mostrar</Form.Label>
                                        <Col>
                                            <Form.Control size="sm" id="sizePerPage" as="select" onChange={(e) => this.handleSizePerPage(paginationProps, e.target.value)}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value={infoFinal.length}>Todos</option>
                                            </Form.Control>
                                        </Col>
                                    </div>
                                    <Col>
                                        <PaginationTotalStandalone {...paginationProps} />
                                    </Col>
                                    <div className="d-flex flex-row-reverse">
                                        <Col>
                                            <PaginationListStandalone {...paginationProps} />
                                        </Col>
                                        <Form.Label column>Página</Form.Label>
                                    </div>
                                </Form.Row>
                            </fieldset>
                        </Form>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                keyField='localId'
                                data={infoFinal}
                                columns={columns}
                                bordered={false}
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    openVentaDia(local, event) {
        console.log("openVentaDia: ", local)
        event.preventDefault();
        this.setState({
            local: {
                localId: local.localId,
                fecha: local.fecha,
                localAlias: local.localAlias
            },
            status: true
        })
    }

    render() {
        Moment.locale('es');
        if (!!this.state.status) {
            return <Redirect to={{
                pathname: "/ventaDia",
                state: {
                    local: this.state.local
                }
            }}
            />
        }
        return (
            <section id="dashboardEstatus" className="text-center">
                <Container className="justify-content-center containerEstatus" fluid="true">
                    {/*<LocalesChart>{this.state.ventasLocales}</LocalesChart>*/}
                    <Card className="card-custome-estatus" style={{ border: "2px solid rgba(238,39,55,1)" }}>
                        <Card.Header className="card-header-reporte"><h5>Concentrado diario hasta {this.state.fechaHoy}</h5> </Card.Header>
                        <Card.Body>
                            {this.state.request ? "Cargando..." : this.mostrarRegistros()}
                        </Card.Body>
                    </Card>
                </Container >
            </section >
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedLocalEstatus = connect(mapStateToProps)(LocalEstatus);
export { connectedLocalEstatus as LocalEstatus }