import { userConstants } from '../constantes/user-constants'
import { alertActions } from '../acciones/alert-actions'
import { history } from '../helpers/history'
import axios from 'axios'
import { KRAKEN_AUTH, SERVICE_ID } from '../constants';

export const userActions = {
    login,
    logout
}

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }));

        axios.post(
            KRAKEN_AUTH,
            {
                "user": email,
                "password": password,
                "serviceId": SERVICE_ID
            }
        ).then((res)=>{
            if(res.data.success){
                localStorage.setItem('user', JSON.stringify(res.data.authKooben))
                dispatch(success(res.data.authKooben));
                history.push('/estatus')
            }else{
                dispatch(failure("Error de autenticación"))
                dispatch(alertActions.error("Error de autenticación"))
            }
        }).catch((error)=>{
            dispatch(failure("Usuario y/o contraseña incorrectos"));
            dispatch(alertActions.error("Usuario y/o contraseña incorrectos"))
        })
    }

    /*Most of the actions for users are async actions that are made up of multiple sub actions, 
   this is because they have to make an http request and wait for the response before completing. 
   Async actions typically dispatch a request action before performing an async task (e.g. an http request), 
   and then dispatch a success or error action based on the result of the async task.*/
    function request(email) { return { type: userConstants.LOGIN_REQUEST, email } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    localStorage.removeItem('user')
    return { type: userConstants.LOGOUT }
}