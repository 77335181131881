import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../constants';
import { KRAKEN_URL } from '../../constants';

//Estilos
import MaterialIcon from 'material-icons-react';
import "./ListaVentaDia.css"
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.min.css';

//Diseño
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Alert from 'react-bootstrap/Alert';

//Utilerias
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import es from 'date-fns/locale/es';
import { addDays } from 'date-fns';
import Graficas from './Graficas';
import { ToastContainer, toast } from 'react-toastify';

class ListaVentaDia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jwt: this.props.user,
            linea: '',
            local: this.props.location.state.local,
            request: true,
            ventaPersonalizada: [],
            venta: [],
            fecha_inicial: new Date(),
            fecha_final: new Date(),
            error: ''
        };
        this.handleChangeInicial = this.handleChangeInicial.bind(this);
        this.handleChangeFinal = this.handleChangeFinal.bind(this);
    }

    componentDidMount() {
        let authKooben = this.state.jwt;
        let base64url = authKooben.split('.')[1];
        let decKooben = JSON.parse(window.atob(base64url));
        /**this.setState({
            linea: decKooben.sub,
        });*/
        this.getLinea(decKooben.lineaId);
        this.getHdiaFinal(this.formatFecha(this.state.fecha_inicial), this.formatFecha(this.state.fecha_final));
    }

    getLinea(lineaId) {
        axios.post( KRAKEN_URL + 'lineas/lineaId',
            {
                "lineaId": lineaId
            },
            {
                headers: { 'Authorization': 'Bearer ' + this.state.jwt }
            }).then((res) => {
            this.setState({
                linea: res.data.linea.razonSocial,
                request: false
            });
        }).catch((err) => {
            this.setState({
                error: err,
                request: false
            });
        });
    }

    formatFecha = (fecha) => {
        let formFecha = fecha.getFullYear() + "-" + (fecha.getMonth() + 1) + "-" + fecha.getDate();
        return formFecha;
    }

    handleChangeInicial(fechaI) {
        this.setState({
            fecha_inicial: fechaI
        });
    }
    handleChangeFinal(fechaF) {
        this.setState({
            fecha_final: fechaF
        });
        this.getHdiaFinal(this.formatFecha(this.state.fecha_inicial), this.formatFecha(fechaF));
    }

    getHdiaFinal = (fechaInicial, fechaFinal) => {
        let formHoy = this.formatFecha(new Date());

        if ((fechaInicial === fechaFinal) && (fechaInicial !== formHoy)) {
            if (this.state.local !== undefined) {
                axios.post(API_URL + 'getListHDiaFinal',
                    {
                        "fecha": fechaInicial,
                        "fechaDos": fechaFinal,
                        "localId": this.state.local.localId
                    },
                    {
                        headers: { 'Authorization': 'Bearer ' + this.state.jwt }
                    }).then(res => {
                        this.setState({
                            venta: res.data.listHDiaFinal,
                            request: false
                        });
                        this.mostrarVenta();
                    }).catch(err => {
                        this.setState({ error: err, request: false })
                    });
            } else {
                this.setState({
                    request: false
                });
            }
        } else if ((fechaInicial === fechaFinal) && (fechaInicial === formHoy)) {
            if (this.state.local !== undefined) {
                axios.post(API_URL + 'getListHDiaFinal',
                    {
                        "fecha": this.state.local.fecha,
                        "fechaDos": this.state.local.fecha,
                        "localId": this.state.local.localId
                    },
                    {
                        headers: { 'Authorization': 'Bearer ' + this.state.jwt }
                    }).then(res => {
                        this.setState({
                            venta: res.data.listHDiaFinal,
                            request: false
                        });
                        this.mostrarVenta();
                    }).catch(err => {
                        this.setState({ error: err, request: false })
                    });
            } else {
                this.setState({
                    request: false
                });
            }
        } else if (fechaInicial !== fechaFinal) {
            if (this.state.local !== undefined) {
                axios.post(API_URL + 'getListHDiaFinal',
                    {
                        "fecha": fechaInicial,
                        "fechaDos": fechaFinal,
                        "localId": this.state.local.localId
                    },
                    {
                        headers: { 'Authorization': 'Bearer ' + this.state.jwt }
                    }).then(res => {
                        this.setState({
                            ventaPersonalizada: res.data.listHDiaFinal,
                            request: false
                        });
                        this.mostrarSumaVentas();
                    }).catch(err => {
                        this.setState({ error: err, request: false })
                    });
                this.mostrarSumaVentas();
            } else {
                this.setState({
                    request: false
                });
            }
        }
    }

    reportes = (e, formFechaInicial, formFechaFinal, linea, idLocal, local) => {
        e.preventDefault();
        formFechaInicial === formFechaFinal
            ?
            this.descargarReporteDia(e, formFechaInicial, linea, idLocal, local)
            :
            this.descargarReportePersonalizado(e, formFechaInicial, formFechaFinal, linea, idLocal, local);
    }

    //Función para convertir una cadena de texto de base64 a arrayBuffer
    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    //Función que convierte un arrayBuffer a un archivo pdf y hace la descarga del mismo
    saveByteArray(reportName, byte) {
        var blob = new Blob([byte], {
            type: "application/pdf"
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    }

    descargarReporteDia = (e, fecha, linea, idLocal, local) => {
        e.preventDefault();
        let vts = this.state.venta;
        axios.post(API_URL + `reporteDia/${fecha}/${linea}/${idLocal}/${local}`, {
            vts
        },
        {
            headers: { 'Authorization': 'Bearer ' + this.state.jwt }
        }).then(venta => {
            var arrayBuffer = this.base64ToArrayBuffer(venta.data.bytes);//Convertimos los bytes de base64 a un array buffer
            this.saveByteArray(venta.data.reportName, arrayBuffer);//Le pasamos el arrayBuffer para obtener el archivo pdf
        }).catch(err => {
            this.setState({ 
                error: err, 
                request: false
            });
            console.log("Error: ", err);
            toast.error("Ocurrió un error al generar reporte");
        })
    }

    descargarReportePersonalizado = (e, fecha, fechaDos, linea, idLocal, local) => {
        e.preventDefault();
        let vts = this.state.ventaPersonalizada;
        axios.post(API_URL + `reportePersonalizado/${fecha}/${fechaDos}/${linea}/${idLocal}/${local}`, {
            vts
        },
        {
            headers: { 'Authorization': 'Bearer ' + this.state.jwt }
        }).then(ventaspf => {
            var arrayBuffer = this.base64ToArrayBuffer(ventaspf.data.bytes);//Convertimos los bytes de base64 a un array buffer
            this.saveByteArray(ventaspf.data.reportName, arrayBuffer);//Le pasamos el arrayBuffer para obtener el archivo pdf           
        }).catch(err => {
            this.setState({ 
                error: err, 
                request: false
            });
            console.log("Error: ", err);
            toast.error("Ocurrió un error al generar reporte");
        });
    }

    refreshPage = () => {
        window.location.reload();
    }

    mostrarSumaVentas = () => {
        const vtas = this.state.ventaPersonalizada;
        let totalCtas = 0, totalVN = 0, totalI =0, totalVT = 0, totalDesc = 0, totalVB = 0, totalPers = 0, totalCosto = 0, 
        totalConsumo = 0, totalCostoP = 0, totalCuentaProm =0;

        vtas.forEach(vtas => {
            totalCtas = totalCtas + parseInt(vtas.NCtas);
            totalVN = totalVN + parseInt(vtas.vneta);
            totalI = totalI + parseInt(vtas.viva);
            totalVT = totalVT + parseInt(vtas.vtotal);
            totalDesc = totalDesc + parseInt(vtas.vdesc);
            totalVB = totalVB + parseInt(vtas.vbruta);
            totalPers = totalPers + parseInt(vtas.NPers);
            totalCosto = totalCosto + parseInt(vtas.costo);
            totalConsumo = totalVN / totalPers;
            totalCostoP = (totalCosto * 100) / totalVN;
            totalCuentaProm = totalVN / totalCtas;
        });
        //console.log("total: cuentas: ", totalCtas, " vneta: ", totalVN, " vtotal: ", totalVT, " vbruta: ", totalVB,
        //" personas: ", totalPers, " costo: ", totalCosto, " consumo: ", totalConsumo, " costo % ", totalCostoP);

        if (this.state.error) {
            console.log(this.state.error);
            return (
                <div className="background-body font-body text-center cardCentrado">
                    <Alert variant="danger" onClose={() => this.refreshPage()} dismissible>
                        <Alert.Heading>¡Ha ocurrido un error inesperado!</Alert.Heading>
                    </Alert>
                </div>
            );
        }

        return this.state.local.fecha !== undefined ? (

            <tr key={vtas}>
                <td>{this.state.local.localAlias}</td>
                <td>{totalCtas}</td>
                <td><NumberFormat value={totalVN} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                <td><NumberFormat value={totalI} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                <td><NumberFormat value={totalVT} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                <td><NumberFormat value={totalDesc} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                <td><NumberFormat value={totalVB} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                <td>{totalPers}</td>
                <td><NumberFormat value={totalConsumo.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                <td><NumberFormat value={totalCuentaProm.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                <td><NumberFormat value={totalCosto} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                <td><NumberFormat value={totalCostoP.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                <td>
                    <ButtonGroup id="button-group">
                        <button className="btn" size="sm" onClick={e => this.reportes(e, this.formatFecha(this.state.fecha_inicial), this.formatFecha(this.state.fecha_final), "Linea Test SA de CV", this.state.local.localId, this.state.local.localAlias)}>
                            <MaterialIcon icon="get_app" className="material-icons"></MaterialIcon>
                        </button>
                    </ButtonGroup>
                </td>
            </tr>
        )
            : <tr><td>Error</td></tr>
    }

    mostrarVenta = () => {
        const vta = this.state.venta;
        //Cálculos para costo porcentual
        let consPp = vta.length === 0 || undefined ? 0 : Number((vta[0].vneta / vta[0].NPers).toFixed(2));
        let cpDefault = vta.length === 0 || undefined ? 0 : (30 * vta[0].vneta) / 100;
        let costoP = vta.length === 0 || undefined ? 0 : vta[0].costo === 0 || null
            ? Number((cpDefault * 100 / vta[0].vneta).toFixed(1))
            : Number(((vta[0].costo * 100) / vta[0].vneta).toFixed(1));
        let cuentaProm = vta.length === 0 || undefined ? 0 : Number(vta[0].vneta / vta[0].NCtas).toFixed(2);

        if (this.state.error) {
            console.log(this.state.error);
            return (
                <div className="background-body font-body text-center cardCentrado">
                    <Alert variant="danger" onClose={() => this.refreshPage()} dismissible>
                        <Alert.Heading>¡Ha ocurrido un error inesperado!</Alert.Heading>
                    </Alert>
                </div>
            );
        }
        return this.state.venta.length ? this.state.venta.map(vtas => {
            return (
                <tr key={vtas.id}>
                    <td>{this.state.local.localAlias}</td>
                    <td>{vtas.NCtas}</td>
                    <td><NumberFormat value={vtas.vneta} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                    <td><NumberFormat value={vtas.viva} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                    <td><NumberFormat value={vtas.vtotal} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                    <td><NumberFormat value={vtas.vdesc} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                    <td><NumberFormat value={vtas.vbruta} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                    <td>{vtas.NPers}</td>
                    <td><NumberFormat value={consPp} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                    <td><NumberFormat value={cuentaProm} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                    <td><NumberFormat value={vtas.costo} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                    <td><NumberFormat value={costoP} displayType={'text'} thousandSeparator={true} prefix={'%'}></NumberFormat></td>
                    <td>
                        <ButtonGroup id="button-group">
                            <button className="btn" size="sm" onClick={e => this.reportes(e, this.formatFecha(this.state.fecha_inicial), this.formatFecha(this.state.fecha_final), this.state.linea, vtas.id.idLocal, this.state.local.localAlias)}>
                                <MaterialIcon icon="get_app" className="material-icons"></MaterialIcon>
                            </button>
                        </ButtonGroup>
                    </td>
                </tr>
            );
        })
            : <tr className="cardCentradoVenta">
                <td className="cardCentradoVenta" colSpan="11">{/*<h5 className="centrar-texto-ventas">*/}No hay registros que mostrar{/*</h5>*/}</td>
            </tr>
    }

    render() {
        let formFechaInicial = this.formatFecha(this.state.fecha_inicial);
        let formFechaFinal = this.formatFecha(this.state.fecha_final);
        return (
            <section id="listaVentaDia" className="background-body font-body text-center">
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Container className="justify-content-center containerVentas" fluid="true">
                    <Card className="card-custome bordeVenta">
                        <Card.Header className="card-header-ventas" style={{ color: "#FFFFF" }}>
                            <h5> Venta Día</h5>
                            <DatePicker className="calendarioVentas"
                                id="calendarioInicio"
                                locale={es}
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.fecha_inicial}
                                onChange={this.handleChangeInicial}
                                maxDate={addDays(new Date(), 0)}
                                isClearable={true}
                                placeholderText="Selecciona fecha inicio"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Selecciona fecha inicio"
                            />
                            <DatePicker className="calendarioVentas"
                                id="calendarioFin"
                                locale={es}
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.fecha_final}
                                onChange={this.handleChangeFinal}
                                maxDate={addDays(new Date(), 0)}
                                isClearable={true}
                                placeholderText="Selecciona fecha fin"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Selecciona fecha fin"
                            />
                        </Card.Header>
                        <Card.Body>
                            <Table responsive size="sm" style={{ color: "#212529" }}>
                                <thead>
                                    <tr className="cabTabla">
                                        <th>Local</th>
                                        <th># Cuentas</th>
                                        <th>Venta neta</th>
                                        <th>IVA</th>
                                        <th>Venta Total</th>
                                        <th>Descuento</th>
                                        <th>Venta Bruta</th>
                                        <th># Personas</th>
                                        <th>Cons. p/persona</th>
                                        <th>Cuenta promedio</th>
                                        <th>Costo total</th>
                                        <th>Costo %</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.request ? <tr><td>Cargando...</td></tr> : formFechaInicial === formFechaFinal ? this.mostrarVenta() : this.mostrarSumaVentas()}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <Graficas>{formFechaInicial === formFechaFinal
                        ? this.state.venta
                        : this.state.ventaPersonalizada
                    }</Graficas>
                </Container>
            </section>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedListaVentaDia = connect(mapStateToProps)(ListaVentaDia);
export { connectedListaVentaDia as ListaVentaDia }