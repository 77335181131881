//Librerías
import React, { Component } from 'react'
//import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

//Estilos
import './Login.css'
import MaterialIcon from 'material-icons-react'

//Elementros bootstrap
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button';

//Imágenes
import logo from '../img/cheffsysLOGO.svg'
import { userActions } from '../../acciones/user-actions';

class Login extends Component {
    constructor(props) {
        super(props);

        this.props.dispatch(userActions.logout())

        this.state = {
            email: '',
            password: '',
            status: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        //this.setState({ submitted: true });
        const { email, password } = this.state;
        const { dispatch } = this.props;
        
        if (email && password) {
            dispatch(userActions.login(email, password));
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { email, password } = this.state;
        return (
            <section id="login">
                <Container className="container-back">
                    <Row className="row-background">
                        <Col xs={4} lg={5} className="backgroud-one">
                            <img className="div-img" src={logo} alt="Kooben" />
                        </Col>
                        <Col className="backgroud-two">
                            <h3 className="text-center pb-4">Iniciar Sesión</h3>
                            <Row className="row-config">
                                <Col xs={1} className="col-back">
                                    <MaterialIcon icon="person"></MaterialIcon>
                                </Col>
                                <Col>
                                    <Form.Control name="email" type="email" className="form-control" id="nombreUsuario" placeholder="Usuario" value={email} onChange={this.handleChange} />
                                </Col>
                            </Row>
                            <Row className="row-config">
                                <Col xs={1} className="col-back">
                                    <MaterialIcon icon="vpn_key"></MaterialIcon>
                                </Col>
                                <Col>
                                    <Form.Control name="password" type="password" id="password" placeholder="Contraseña" value={password} onChange={this.handleChange} />
                                </Col>
                            </Row>
                            <Form.Row className="justify-content-center">
                                <Button variant="light" className="btn-login" size="md" onClick={(event) => this.handleSubmit(event)}>Ingresar</Button>
                                {loggingIn}
                            </Form.Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

const connectedLogin = connect(mapStateToProps)(Login);
export { connectedLogin as Login }