import { alertConstants } from '../constantes/alert-constants'

export const alertActions = {
    success,
    error,
    clear
};

/*For example to display a success alert message with the text 'Great Job!' 
you can call dispatch(alertActions.success('Great Job!'));.*/

function success(message) {
    return {
        type: alertConstants.SUCCESS,
        message
    };
}

function error(message) {
    return {
        type: alertConstants.ERROR,
        message
    };
}

function clear() {
    return {
        type: alertConstants.CLEAR
    }
}