import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'

//
import MaterialIcon from 'material-icons-react';
import "./ModalCuentaAcceso.css"
import axios from 'axios';
import { KRAKEN_URL } from '../../constants';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

class ModalCuentaAcceso extends Component {

    handleChangeNombre(e){
        document.getElementById("nombre").value = e.target.value;
    }
    handleChangeCorreo(e){
        document.getElementById("correo").value = e.target.value;
    }
    handleChangeContrasena(e){
        document.getElementById("contrasena").value = e.target.value;
    }
    handleChangeConfirmContrasena(e){
        document.getElementById("confirmContrasena").value = e.target.value;
    }

    actualizarCuenta = (e) => {
        e.preventDefault();
        
        axios.post( KRAKEN_URL + 'locales/updateCuentaLocal', 
        {
            "localId": this.props.children.localId,
            "nombreLocal": document.getElementById("nombre").value,
            "correo": document.getElementById("correo").value,
            "nuevaContrasena": document.getElementById("contrasena").value,
            "confirmarNuevaContrasena" : document.getElementById("confirmContrasena").value
        }
        ).then(res => {
            if(res.data.result.success){
                toast.success(res.data.result.message);
                setTimeout(function(){
                    window.location.reload(true);
                }, 3000);
            }else if(!res.data.result.success){
                toast.error(res.data.result.message);   
            }
        }).catch(err => {
            console.log( err);     
        })

    }
    render() {
        let nombre = this.props.children.nombreLocal;        
        let correo = this.props.children.correo;
        return (
            <section id="modalAgregarLocal" className="background-body font-body text-center">
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Container>
                        <Modal.Header closeButton>
                            <Modal.Title>Actualizar cuenta de acceso</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="formLocales">
                                <fieldset>
                                    <legend>Información</legend>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre</Form.Label>
                                            <Form.Control id="nombre" type="text" placeholder="Ingrese nombre del local"
                                                onChange={e => this.handleChangeNombre(e)}
                                                defaultValue={nombre}
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Correo electrónico</Form.Label>
                                            <Form.Control id="correo" type="email" placeholder="Ej: correo@algo.com"
                                                onChange={e => this.handleChangeCorreo(e)}
                                                defaultValue={correo}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Contraseña</Form.Label>
                                            <Form.Control id="contrasena" type="password" placeholder="Ingresa contraseña"
                                                onChange={e => this.handleChangeContrasena(e)}
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Confirmar contraseña</Form.Label>
                                            <Form.Control id="confirmContrasena" type="password" placeholder="Ingresa contraseña"
                                                onChange={e => this.handleChangeConfirmContrasena(e)}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <ButtonGroup>
                                <button type="button" className="btn accionGuardar"  onClick={e => this.actualizarCuenta(e)}>
                                    <MaterialIcon icon="done" className="material-icons iconAlign"></MaterialIcon>
                                    &nbsp;Actualizar
                                    </button>
                                <button type="button" className="btn accionCancelar" onClick={this.props.onHide}>
                                    <MaterialIcon icon="close" className="material-icons iconAlign"></MaterialIcon>
                                    &nbsp;Cancelar
                                    </button>
                            </ButtonGroup>
                        </Modal.Footer>
                    </Container>
                </Modal>
            </section>
        );
    }
}

export default ModalCuentaAcceso;