import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { API_URL, KRAKEN_URL } from '../../constants';

//Estilos
import MaterialIcon from 'material-icons-react';
import "./ListadoMezclaProductos.css"
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.min.css';

//Diseño
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

//Componentes
import DatePicker from "react-datepicker";
import es from 'date-fns/locale/es';
import { addDays } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';

class ListaMezclaProductos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jwt: this.props.user,
            linea_restaurante: '',
            modif: false,
            request: true,
            productos: [],
            fecha_inicial: new Date(),
            fecha_final: new Date(),
            error: ''
        };
        this.handleChangeInicial = this.handleChangeInicial.bind(this);
        this.handleChangeFinal = this.handleChangeFinal.bind(this);
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
    }

    componentDidMount() {
        let authKooben = this.state.jwt;
        let base64url = authKooben.split('.')[1];
        let decKooben = JSON.parse(window.atob(base64url));
        /**this.setState({
            id_linea: decKooben.lineaId
        });**/
        console.log("datos linea: ", decKooben.lineaId, decKooben.alias, "id_linea estatus: ", this.state.id_linea);
        console.log("jwt: ", this.state.jwt);
        
        this.getLinea(decKooben.lineaId);
    }

    getLinea(lineaId) {
        axios.post( KRAKEN_URL + 'lineas/lineaId',
            {
                "lineaId": lineaId
            },
            {
                headers: { 'Authorization': 'Bearer ' + this.state.jwt }
            }
        ).then((res) => {
            this.setState({
                linea_restaurante: res.data.linea.razonSocial,
                request: false
            });
        }).catch((err) => {
            this.setState({
                error: err,
                request: false
            });
        });
    }

    formatFecha = (fecha) => {
        let formFecha = fecha.getFullYear() + "-" + (fecha.getMonth() + 1) + "-" + fecha.getDate();        
        return formFecha;
    }

    handleChangeInicial(fechaI) {
        this.setState({
            fecha_inicial: fechaI
        });
    }

    handleChangeFinal(fechaF) {
        this.setState({
            fecha_final: fechaF
        });
    }

    handleChangeCheckBox(event){
        const target = event.target;
        const checkboxM = target.checked ? true : false;
        this.setState({
            modif: checkboxM
        });
    }

    //Función para convertir una cadena de texto de base64 a arrayBuffer
    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    //Función que convierte un arrayBuffer a un archivo pdf y hace la descarga del mismo
    saveByteArray(reportName, byte) {
        var blob = new Blob([byte], {
            type: "application/pdf"
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    }
    
   descargarReporteProductos = (e, fechaInicio, fechaFin, linea_restaurante, modif) => {
        e.preventDefault();
        console.log("params: ", fechaInicio, fechaFin, linea_restaurante, modif);
        
        let fechaImpresion = this.formatFecha(new Date());
        axios.post(API_URL + `reporteProductos/${fechaInicio}/${fechaFin}/${linea_restaurante}/${modif}`,
            {
                fechaImpresion
            },
            {
                headers: { 'Authorization': 'Bearer ' + this.state.jwt }
            }
        ).then(res => {
            var arrayBuffer = this.base64ToArrayBuffer(res.data.bytes);
            this.saveByteArray(res.data.reportName, arrayBuffer);                
        }).catch(err => {
            this.setState({ 
                error: err, 
                request: false
            });
            console.log("Error: ", err);
            toast.error("Ocurrió un error al generar reporte");
        });
   }

    render(){
        return(
            <section id="mezclaProductos" className="background-body font-body text-center">
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Container className="justify-content-center containerProductos" fluid="true">
                        <Card className="card-custome bordeProductos">
                            <Card.Header className="card-header-productos" style={{ color: "#FFFFF" }}>
                                <h4> Productos</h4>
                                <DatePicker className="calendarioProductos"
                                    id= "calendario"
                                    locale={es}
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.fecha_inicial}
                                    onChange={this.handleChangeInicial}
                                    maxDate={addDays(new Date(), 0)}
                                    isClearable={true}
                                    placeholderText="Selecciona fecha inicio"
                                    title="Selecciona fecha inicio"
                                />
                                <DatePicker className="calendarioProductos"
                                    id= "calendario"
                                    locale={es}
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.fecha_final}
                                    onChange={this.handleChangeFinal}
                                    maxDate={addDays(new Date(), 0)}
                                    isClearable={true}
                                    placeholderText="Selecciona fecha fin"
                                    title="Selecciona fecha fin"
                                />
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <fieldset className="fieldsetProductos">
                                    <legend className="legendProductos">Generar reporte</legend>                                    
                                        
                                        <ButtonGroup id="button-group">
                                        <Form.Row as={Col}>
                                            <Form.Group as={Col}>
                                                {/**<Form.Label htmlFor="modif">modif</Form.Label>**/}
                                                <Form.Check type="checkbox" name="modif" onChange={this.handleChangeCheckBox} />modif
                                                {/**<Form.Check type="checkbox" name="modif" value={this.state.modif} />**/}
                                            </Form.Group>
                                            
                                        </Form.Row>
                                                <button className="btn rounded" size="lg" 
                                                    /**onClick={e => this.descargarReporteProductos(e, this.formatFecha(this.state.fecha_inicial), this.formatFecha(this.state.fecha_final), this.state.id_linea, this.state.linea, this.state.local, this.state.grupo, this.state.lineaProd)}>**/
                                                    onClick={e => this.descargarReporteProductos(e, this.formatFecha(this.state.fecha_inicial), this.formatFecha(this.state.fecha_final), this.state.linea_restaurante, this.state.modif)}>
                                                <h5 style={{ color: "#855fa5d1" }}>Reporte&nbsp;
                                                    <MaterialIcon icon="picture_as_pdf" className="material-icons icon"></MaterialIcon>
                                                </h5></button>
                                            </ButtonGroup>
                                    </fieldset>
                                </Form>
                            </Card.Body>
                        </Card>
                </Container>
            </section>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedListaMezclaProductos = connect(mapStateToProps)(ListaMezclaProductos);
export { connectedListaMezclaProductos as ListaMezclaProductos }