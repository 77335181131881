import React, { Component, Fragment } from 'react';
import './App.css';

import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

//Componentes
import { Login } from './componentes/Login/Login'
import { Header } from './componentes/Header/Header'
//import LocalEstatus from './componentes/LocalesEstatus/LocalEstatus'

import { history } from './helpers/history'
import { alertActions } from './acciones/alert-actions';
import { PrivateRoute } from './componentes/PrivateRoute/PrivateRoute';

class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
      dispatch(alertActions.clear());
    })
  }
  render() {
    const { alert } = this.props;
    return (
      <div>
        {alert.message &&
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        }
        <Router history={history}>
          <Fragment>
            <Switch>
              <Route exact path="/" component={Login}></Route>
              <Fragment>
                <PrivateRoute component={Header} />
              </Fragment>
            </Switch>
          </Fragment>
        </Router >
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
