//Librerías
import React, { Component } from 'react';

//Elementos bootstrap
import Modal from 'react-bootstrap/Modal'

//Imágenes
import logo from '../img/cheffsysLOGO.svg'

//Componentes
import NumberFormat from 'react-number-format';
import Moment from 'moment';

//Estilo
import './ModalVerTicket.css'

class ModalVerTicket extends Component {

    infoHeaderTicket = () => {
        var ticket = this.props.children;
        if (ticket["0"] !== undefined) {
            return (
                <div>
                    <div className="row">
                        <div className="col-3">FOLIO</div>
                        <div className="col">{ticket["0"].generalTicket.hcomaFolio}</div>
                    </div>
                    <div className="row">
                        <div className="col-3">#CTA</div>
                        <div className="col">{ticket["0"].hctas["0"].cuenta}</div>
                    </div>
                    <div className="row">
                        <div className="col-3">FECHA</div>
                       {/**<div className="col">{ticket["0"].generalTicket.fecha.substring(0, 10)}</div>**/}
                        <div className="col">{Moment(ticket["0"].generalTicket.fecha.substring(0, 10)).format('DD/MM/YYYY') }</div>
                    </div>
                    <div className="row">
                        <div className="col-3">HORA</div>
                        <div className="col">{ticket["0"].generalTicket.hcomaFechaApertura.substring(11, 19).concat(" - " + ticket["0"].generalTicket.hcomaFechaCierre.substring(11, 19))}</div>
                    </div>
                    <div className="row">
                        <div className="col-3">RESP.</div>
                        <div className="col">{ticket["0"].hctas["0"].emp}</div>
                    </div>
                    <div className="row">
                        <div className="col-3">SERV.</div>
                        <div className="col">RESTAURANTE</div>
                    </div>
                    <div className="row">
                        <div className="col-3">MESA</div>
                        <div className="col">{ticket["0"].generalTicket.hcomaMesa}</div>
                    </div>
                    <div className="row">
                        <div className="col-3">#PERS.</div>
                        <div className="col">{ticket["0"].generalTicket.numComensales}</div>
                    </div>
                </div>
            )
        }
    }

    infoConceptosTicket = () => {
        var ticket = this.props.children;
        if (ticket["0"] !== undefined) {
            const tabla = ticket["0"].conceptosTicket.map((concepto, index) => {
                return (
                    <tr key={index} className="row">
                        <td className="col-1">{concepto.porciones}</td>
                        <td className="col-5">{concepto.producto}</td>
                        <td className="col left-cell"><NumberFormat value={concepto.precioUnit.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></td>
                    </tr>
                )
            })
            return tabla;
        }
    }

    infoFooterTicket = () => {
        var ticket = this.props.children;
        if (ticket["0"] !== undefined) {
            let articulos = 0;
            ticket["0"].conceptosTicket.forEach(t => {
                articulos = articulos + t.porciones;
            })
            return (
                <div>
                    <div className="row">
                        <div className="col-7">TOTAL</div>
                        <div className="col-5 left-cell"><NumberFormat value={ticket["0"].generalTicket.hcomaImporte.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">{ticket["0"].hctas["0"].formaPago}</div>
                    </div>
                    <div className="row">
                        <div className="col-7">PROPINA</div>
                        <div className="col-5 left-cell">{<NumberFormat value={ticket["0"].hctas["0"].abonoPropina.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat>}</div>
                    </div>
                    <div className="row">
                        <div className="col">Nota de consumo simplificado</div>
                    </div>
                    <div className="row">
                        <div className="col-4">#Artículos.</div>
                        <div className="col">{articulos}</div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <section id="modalVerTicket" className="background-body font-body text-center">
                <Modal
                    {...this.props}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="modal-header" closeButton></Modal.Header>
                    <div className="text-center">
                        <img src={logo} alt="Kooben" width="170 px" className="img-back-modal" />
                        <div>CHEFFSYS</div>
                        <div>Sys Intangibles S.A. de C.V.</div>
                        <div>Adolfo Prieto 1669 Int-703</div>
                        <div>Col. Del Valle</div>
                        <div>Ciudad de México</div>
                    </div>
                    <Modal.Body>
                        {this.infoHeaderTicket()}
                        <br />
                        <div className="modal-table">
                            <table >
                                <tbody>
                                    {this.infoConceptosTicket()}
                                </tbody>
                            </table>
                        </div>
                        <br />
                        {this.infoFooterTicket()}
                        <br />
                        <div className="text-center">
                            <div>GRACIAS POR SU VISITA</div>
                            <div>VUELVA PRONTO</div>
                            <div>Software POS: www.cheffsys.com</div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        );
    }
}

export default ModalVerTicket;