import React, { Component } from 'react';
import axios from 'axios';
import { KRAKEN_URL } from '../../constants';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'

//
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css' 
import MaterialIcon from 'material-icons-react';
import "./FormLocales.css"

class FormLocales extends Component {
    state = {
        lineaId: '',
        linea:'',
        rfc: '',
        tipoPersona: '',
        localAlias: '',
        telefono: '',
        correo: '',
        contrasena: '',
        confirmarContrasena: '',
        calle: '',
        numeroExt: '',
        numeroInt: '',
        colonia: '',
        municipio: '',
        cp: '',
        estado: '',
        ciudad: '',
        pais: '',
        status: '',
        error: ''
    }
    handleRfcChange(event) {
        this.setState({
            rfc: event.target.value
        })
    }

    handleTipoPersonaChange(event) {
        this.setState({
            tipoPersona: event.target.value
        })
    }

    handleLocalAliasChange(event) {
        this.setState({
            localAlias: event.target.value
        })
    }

    handleTelefonoChange(event) {
        this.setState({
            telefono: event.target.value
        })
    }

    handleCorreoChange(event) {
        this.setState({
            correo: event.target.value
        })
    }

    handleContrasenaChange(event) {
        this.setState({
            contrasena: event.target.value
        })
    }

    handleConfirmarContrasenaChange(event) {
        this.setState({
            confirmarContrasena: event.target.value
        })
    }

    handleCalleChange(event) {
        this.setState({
            calle: event.target.value
        })
    }

    handleNumeroExtChange(event) {
        this.setState({
            numeroExt: event.target.value
        })
    }

    handleNumeroIntChange(event) {
        this.setState({
            numeroInt: event.target.value
        })
    }

    handleColoniaChange(event) {
        this.setState({
            colonia: event.target.value
        })
    }

    handleMunicipioChange(event) {
        this.setState({
            municipio: event.target.value
        })
    }

    handleCpChange(event) {
        this.setState({
            cp: event.target.value
        })
    }

    handleEstadoChange(event) {
        this.setState({
            estado: event.target.value
        })
    }

    handleCiudadChange(event) {
        this.setState({
            ciudad: event.target.value
        })
    }

    handlePaisChange(event) {
        this.setState({
            pais: event.target.value
        })
    }

    addLocal(event) {
        event.preventDefault();

        const local = {
            rfc: this.state.rfc,
            tipoPersona: this.state.tipoPersona,
            localAlias: this.state.localAlias,
            telefono: this.state.telefono,
            correo: this.state.correo,
            contrasena: this.state.contrasena,
            confirmarContrasena: this.state.confirmarContrasena,
            calle: this.state.calle,
            numeroExt: this.state.numeroExt,
            numeroInt: this.state.numeroInt,
            colonia: this.state.colonia,
            municipio: this.state.municipio,
            cp: this.state.cp,
            estado: this.state.estado,
            ciudad: this.state.ciudad,
            pais: this.state.pais
        }
        console.log(local)

        axios.post(KRAKEN_URL + 'locales/addLocal',
            {
                lineaId: this.props.children[0],
                rfc: local.rfc,
                tipoPersona: local.tipoPersona,
                localAlias: local.localAlias,
                telefono: local.telefono,
                correo: local.correo,
                contrasena: local.contrasena,
                confirmarContrasena: local.confirmarContrasena,
                calle: local.calle,
                numeroExt: local.numeroExt,
                numeroInt: local.numeroInt,
                colonia: local.colonia,
                municipio: local.municipio,
                cp: local.cp,
                estado: local.estado,
                ciudad: local.ciudad,
                pais: local.pais
            }
        ).then((res) => {
            if (res.data.result.success) {
                this.setState({
                    status: true
                });
                toast.success(res.data.result.message);
                setTimeout(function(){
                    window.location.reload(true);
                }, 3000);
                //alert(res.data.result.message)
            }else if(!res.data.result.success){
                this.setState({
                    status: false
                })
                //alert("Las contraseñas no coinciden") 
                console.log("errores", res.data.result);
                toast.error(res.data.result.message);
                toast.error(res.data.result.suggestion);
            }
        }).catch((error) => {
            console.log("Error", error)
            toast.error("Todos los campos marcados con (*) son obligatorios")
            
        })
    }

    render() {
        /*if (this.state.status) {
            window.location.reload(true)
        }*/        
        return (
            <section id="modalAgregarLocal" className="background-body font-body text-center">
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Container>
                        <Modal.Header closeButton>
                            <Modal.Title>Registrar nuevo local</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="formLocales">
                                <fieldset>
                                    <legend>Información</legend>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Línea</Form.Label>
                                            <Form.Control type="text" disabled value={this.props.children[1]}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>RFC *</Form.Label>
                                            <Form.Control type="text" required placeholder="Ingrese rfc"
                                                onChange={(event) => this.handleRfcChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Tipo persona *</Form.Label>
                                            <Form.Control as="select" onChange={(event) => this.handleTipoPersonaChange(event)}>
                                                <option>Elije una opción</option>
                                                <option value="F">Física</option>
                                                <option value="M" defaultValue>Moral</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre *</Form.Label>
                                            <Form.Control type="text" placeholder="Ingrese nombre del local"
                                                onChange={(event) => this.handleLocalAliasChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Teléfono</Form.Label>
                                            <Form.Control type="text" placeholder="Ingresa número telefónico"
                                                onChange={(event) => this.handleTelefonoChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Correo electrónico *</Form.Label>
                                            <Form.Control type="email" placeholder="Ej: correo@algo.com"
                                                onChange={(event) => this.handleCorreoChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Contraseña *</Form.Label>
                                            <Form.Control type="password" placeholder="Ingresa contraseña"
                                                onChange={(event) => this.handleContrasenaChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Confirmar contraseña</Form.Label>
                                            <Form.Control type="password" placeholder="Ingresa contraseña"
                                                onChange={(event) => this.handleConfirmarContrasenaChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                                <fieldset>
                                    <legend>Dirección</legend>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Calle</Form.Label>
                                            <Form.Control id="calle" type="text" placeholder="Ingrese nombre de calle"
                                                onChange={(event) => this.handleCalleChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Número Exterior</Form.Label>
                                            <Form.Control id="numeroExt" type="text" placeholder="Ingrese número ext."
                                                onChange={(event) => this.handleNumeroExtChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Número Interior</Form.Label>
                                            <Form.Control id="numeroInt" type="text" placeholder="Ingrese número int."
                                                onChange={(event) => this.handleNumeroIntChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Colonia</Form.Label>
                                            <Form.Control id="colonia" type="text" placeholder="Ingrese colonia"
                                                onChange={(event) => this.handleColoniaChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Municipio</Form.Label>
                                            <Form.Control id="municipio" type="text" placeholder="Ingrese municipio"
                                                onChange={(event) => this.handleMunicipioChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Código postal</Form.Label>
                                            <Form.Control id="cp" type="text" placeholder="Ingrese cp"
                                                onChange={(event) => this.handleCpChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Estado</Form.Label>
                                            <Form.Control id="edo" type="text" placeholder="Ingrese estado"
                                                onChange={(event) => this.handleEstadoChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Ciudad</Form.Label>
                                            <Form.Control id="ciudad" type="text" placeholder="Ingrese ciudad"
                                                onChange={(event) => this.handleCiudadChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>País</Form.Label>
                                            <Form.Control id="pais" type="text" placeholder="Ingrese país"
                                                onChange={(event) => this.handlePaisChange(event)}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <ButtonGroup>
                                <button type="button" className="btn accionGuardar" onClick={(event) => this.addLocal(event)}>
                                    <MaterialIcon icon="done" className="material-icons iconAlign"></MaterialIcon>
                                    &nbsp;Registrar
                                    </button>
                                <button type="button" className="btn accionCancelar" onClick={this.props.onHide}>
                                    <MaterialIcon icon="close" className="material-icons iconAlign"></MaterialIcon>
                                    &nbsp;Cancelar
                                    </button>
                            </ButtonGroup>
                        </Modal.Footer>
                    </Container>
                </Modal>
            </section>
        );
    }
}

export default FormLocales;