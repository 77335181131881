//Librerías
import React, { Component, useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux';
import { API_URL, KRAKEN_URL } from '../../constants'

//Estilos
import './ListadoTickets.css'
import MaterialIcon from 'material-icons-react'
import 'react-toastify/dist/ReactToastify.min.css';

//Elementros bootstrap
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'

//Componentes
import DatePicker from "react-datepicker";
import es from 'date-fns/locale/es';
import NumberFormat from 'react-number-format';
import { addDays } from 'date-fns';
import ModalVerTicket from './ModalVerTicket';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { ToastContainer, toast } from 'react-toastify';
import Moment from 'moment';

class ListadoTickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jwt: this.props.user,
            request: true,
            error: '',
            locales: [],
            fecha_inicial: new Date(),
            fecha_final: new Date(),
            local: 'todos',
            tamanioPagina: 10,
            listTickets: [],
            generalTicket: [],
            conceptosTicket: [],
            hctasTicket: [],
            ticket: [],
            modalTicketShow: false
        };
        this.handleChangeInicial = this.handleChangeInicial.bind(this);
        this.handleChangeFinal = this.handleChangeFinal.bind(this);
        this.handleChangeIdLocal = this.handleChangeIdLocal.bind(this);
        this.handleSizePerPage = this.handleSizePerPage.bind(this);
    }

    componentDidMount() {
        console.log(this.state.jwt)
        let authKooben = this.state.jwt;
        let base64url = authKooben.split('.')[1];
        let decKooben = JSON.parse(window.atob(base64url));

        this.getLocalesLinea(decKooben.lineaId);
        this.getViewTickets();
    }

    handleChangeInicial(fechaI) {
        this.setState({
            fecha_inicial: fechaI
        });
    }

    handleChangeFinal(fechaF) {
        this.setState({
            fecha_final: fechaF
        });
    }

    handleChangeIdLocal(event) {
        if (event.target.value === 'todos') {
            this.setState({
                local: ''
            })
        } else {
            this.setState({
                local: event.target.value
            });
        }
    }

    handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        this.setState({
            tamanioPagina: newSizePerPage
        });
        onSizePerPageChange(newSizePerPage, page);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.fecha_inicial !== this.state.fecha_inicial) ||
            (prevState.fecha_final !== this.state.fecha_final) ||
            (prevState.local !== this.state.local)) {
            this.getViewTickets();
        }
    }

    getLocalesLinea(lineaId) {
        axios.post(KRAKEN_URL + 'locales/list',
            {
                "lineaId": lineaId
            },
            {
                headers: { 'Authorization': 'Bearer ' + this.state.jwt }
            }            
        ).then((res) => {
            this.setState({
                locales: res.data.locales,
                request: false
            });
        }).catch((err) => {
            this.setState({
                error: err,
                request: false
            });
        });
    }

    cargarSelectLocales = () => {
        if (this.state.error) {
            return (<option>No hay locales registrados</option>);
        }
        return this.state.locales.length ? this.state.locales.map(id_local => {
            return (<option key={id_local.localId} value={id_local.localId}>{id_local.localAlias}</option>)
        }) : "Cargando..."
    }

    getViewTickets = () => {
        let fecha_i = this.state.fecha_inicial;
        let formFechaInicial = fecha_i.getFullYear() + "-0" + (fecha_i.getMonth() + 1) + "-" + fecha_i.getDate();

        let fecha_f = this.state.fecha_final;
        let formFechaFinal = fecha_f.getFullYear() + "-0" + (fecha_f.getMonth() + 1) + "-" + fecha_f.getDate();

        let local = this.state.local === "todos" ? "" : this.state.local;

        axios.post(API_URL + 'listTickets',
            {
                "fechaInicio": formFechaInicial,
                "fechaFin": formFechaFinal,
                "localId": local
            },
            {
                headers: { 'Authorization': 'Bearer ' + this.state.jwt }
            }
        ).then(res => {
            this.setState({
                listTickets: res.data.listTickets,
                request: false
            });
            this.mostrarListTickets();
        }).catch(err => {
            this.setState({ error: err, request: false })
        })
    }

    getInfoTicket(ticket, event) {
        console.log(ticket)
        event.preventDefault();
        axios.post(
            API_URL + 'infoTicket',
            {
                "folio_ticket": ticket.folioTicket
            },
            {
                headers: { 'Authorization': 'Bearer ' + this.state.jwt }
            }
        ).then((res) => {
            if (res.data.result.success) {
                this.setState({
                    generalTicket: ticket,
                    conceptosTicket: res.data.conceptosTicket,
                    hctasTicket: res.data.hctasTicket
                })
                this.getTicket();
            } else {
                toast.info(res.data.result.message);
            }
        }).catch(err => {
            this.setState({ error: err, request: false })
        })
    }

    getTicket() {
        const info = [];
        info.push({
            generalTicket: this.state.generalTicket,
            conceptosTicket: this.state.conceptosTicket,
            hctas: this.state.hctasTicket
        })

        this.setState({
            ticket: info,
            modalTicketShow: true
        })
        //console.log("Ticket: ", this.state.ticket)
    }

    //Función para convertir una cadena de texto de base64 a arrayBuffer
    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    //Función que convierte un arrayBuffer a un archivo pdf y hace la descarga del mismo
    saveByteArray(reportName, byte) {
        var blob = new Blob([byte], {
            type: "application/pdf"
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    }

    printTicket(ticket, event) {
        event.preventDefault();
        axios.post(
            API_URL + 'ticketPDF',
            {
                "folio_ticket": ticket.folioTicket,
                "fecha": ticket.fecha.substring(0, 10),
                "hora": ticket.hcomaFechaApertura.substring(11, 19).concat(" - " + ticket.hcomaFechaCierre.substring(11, 19)),
                "hcomaImporte": ticket.hcomaImporte
            },
            {
                headers: { 'Authorization': 'Bearer ' + this.state.jwt }
            }
        ).then((res) => {
            var arrayBuffer = this.base64ToArrayBuffer(res.data.bytes);//Convertimos los bytes de base64 a un array buffer
            this.saveByteArray(res.data.reportName, arrayBuffer);//Le pasamos el arrayBuffer para obtener el archivo pdf
        }).catch(err => {
            this.setState({ error: err, request: false })
            toast.error("Ocurrió un error al generar reporte");
        })
    }

    refreshPage = () => {
        window.location.reload();
    }

    mostrarListTickets = () => {
        const list = this.state.listTickets;
        if (this.state.error) {
            console.log(this.state.error);
            
            return (
                <div className="background-body font-body text-center cardCentrado">
                    <Alert variant="danger" onClose={() => this.refreshPage()} dismissible>
                        <Alert.Heading>¡Ha ocurrido un error inesperado!</Alert.Heading>
                    </Alert>
                </div>
            );
        } else {
            const columns = [{
                dataField: 'fecha',
                text: 'Fecha',
                formatter: (cellContent, row) => {
                    return (
                        Moment(row.fecha).format('DD/MM/YYYY')
                    )
                }
            }, 
            {
                dataField: 'localId',
                text: 'Local'
            }, {
                dataField: 'hcomaFolio',
                text: 'Folio'
            }, {
                dataField: 'hcomaImporte',
                text: 'Importe',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <NumberFormat value={row.hcomaImporte.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat>
                    )
                }
            },
            {
                dataField: 'acciones',
                isDummyField: true,
                text: 'Acciones',
                formatter: (cellContent, row) => {
                    return (<ButtonGroup>
                        <Button id="verTicket" variant="light" size="sm" data-toggle="tooltip" data-placement="bottom" title="Ver ticket" onClick={(event) => this.getInfoTicket(row, event)}><MaterialIcon icon="remove_red_eye" className="btn-tickets material-icons"></MaterialIcon></Button>
                        <Button id="imprimirTicket" variant="light" size="sm" data-toggle="tooltip" data-placement="bottom" title="Descargar ticket" onClick={(event) => this.printTicket(row, event)}><MaterialIcon icon="picture_as_pdf" className="btn-tickets material-icons"></MaterialIcon></Button>
                    </ButtonGroup>
                    )
                },
            }];

            function indication() {
                return list.length ? "Cargando..." : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size} tickets
                </span>
            );

            const options = {
                custom: true,
                totalSize: list.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form>
                            <fieldset className="fieldsetTickets">
                                <Form.Row>
                                    <div className="d-flex flex-row">
                                        <Form.Label column>Local</Form.Label>
                                        <Col>
                                            <Form.Control size="sm" id="localId" as="select" onChange={this.handleChangeIdLocal}>
                                                <option default value="todos">Todos</option>
                                                {this.state.request ? <option>Cargando...</option> : this.cargarSelectLocales()}
                                            </Form.Control>
                                        </Col>
                                        <Form.Label column>Mostrar</Form.Label>
                                        <Col>
                                            <Form.Control size="sm" id="sizePerPage" as="select" onChange={(e) => this.handleSizePerPage(paginationProps, e.target.value)}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value={list.length}>Todos</option>
                                            </Form.Control>
                                        </Col>
                                    </div>
                                    <Col>
                                        <PaginationTotalStandalone {...paginationProps} />
                                    </Col>
                                    <div className="d-flex flex-row-reverse">
                                        <Col>
                                            <PaginationListStandalone {...paginationProps} />
                                        </Col>
                                        <Form.Label column>Página</Form.Label>
                                    </div>
                                </Form.Row>
                            </fieldset>
                        </Form>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                keyField='folioTicket'
                                data={list}
                                columns={columns}
                                bordered={ false }
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider >
        }
    }

    render() {
        let modalTicketClose = () => this.setState({ modalTicketShow: false });
        return (
            <section id="listaTickets" className="text-center">
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Container className="justify-content-center containerTickets" fluid="true">
                    <Card className="card-custom-tickets" style={{ border: "2px solid rgba(242,141,41,1)" }}>
                        <Card.Header className="card-header-tickets" style={{ color: "#FFFFF" }}>
                            <h5>Tickets</h5>
                            <DatePicker className="calendario-tickets"
                                id="calendario"
                                locale={es}
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.fecha_inicial}
                                onChange={this.handleChangeInicial}
                                maxDate={addDays(new Date(), 0)}
                                isClearable={true}
                                placeholderText="Selecciona fecha inicio"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Selecciona fecha inicio"
                            />
                            <DatePicker className="calendario-tickets"
                                id="calendario"
                                locale={es}
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.fecha_final}
                                onChange={this.handleChangeFinal}
                                maxDate={addDays(new Date(), 0)}
                                isClearable={true}
                                placeholderText="Selecciona fecha fin"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Selecciona fecha fin"
                            />
                        </Card.Header>
                        <div>
                            <ModalVerTicket id="modalTicket" show={this.state.modalTicketShow} onHide={modalTicketClose}>{this.state.ticket}</ModalVerTicket>
                        </div>
                        <Card.Body>
                            {this.state.request ? "Cargando..." : this.mostrarListTickets()}
                        </Card.Body>
                    </Card>
                </Container>
            </section >
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedListadoTickets = connect(mapStateToProps)(ListadoTickets);
export { connectedListadoTickets as ListadoTickets }