//Librerías
import React, { Component } from 'react'
import { connect } from 'react-redux';

//Estilo
import './Header.css'
import MaterialIcon from 'material-icons-react'

//Elementos bootstrap
import Navbar from 'react-bootstrap/Navbar'

//Imágenes
import logo from '../img/cheffsysLOGO.svg'
//

//Componentes
import SideNavMenu from '../Header/SideNavMenu'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jwt: this.props.user,
            alias: ""
        }
    }

    componentDidMount() {
        console.log(this.state.jwt)
        let authKooben = this.state.jwt;
        let base64url = authKooben.split('.')[1];
        let decKooben = JSON.parse(window.atob(base64url));
        this.setState({
            alias: decKooben.alias
        })
    }

    render() {
        return (
            <header>
                <Navbar className="nav-back-color" sticky="top">
                    <Navbar.Brand>
                        <img src={logo} alt="Kooben" width="170 px" className="d-inline-block align-top img-back" />
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text className="font-login-name">
                            {this.state.alias}
                        </Navbar.Text>
                        <MaterialIcon icon="account_circle"></MaterialIcon>
                    </Navbar.Collapse>
                </Navbar>
                <SideNavMenu></SideNavMenu>
            </header>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedHeader = connect(mapStateToProps)(Header);
export { connectedHeader as Header }