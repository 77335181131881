import React, { Component } from 'react';
import axios from 'axios';
import { KRAKEN_URL } from '../../constants';

import Col from 'react-bootstrap/Col'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'

//
import MaterialIcon from 'material-icons-react';
import "./ModalEditarLocal.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

class ModalEditarLocal extends Component {
    handleEstadoChange(event) {
        document.getElementById("estado").value = event.target.value
    }
    handleTipoPersonaChange(event) {
        document.getElementById("tipoPersona").value = event.target.value
    }
    handleMunicipioChange(event) {
        document.getElementById("municipio").value = event.target.value
    }
    handleCalleChange(event) {
        document.getElementById("calle").value = event.target.value
    }
    handleCpChange(event) {
        document.getElementById("cp").value = event.target.value
    }
    handleRfcChange(event) {
        document.getElementById("rfc").value = event.target.value
    }
    handleNumeroExtChange(event) {
        document.getElementById("numeroExt").value = event.target.value
    }
    handleLocalAliasChange(event) {
        document.getElementById("localAlias").value = event.target.value
    }
    handleColoniaChange(event) {
        document.getElementById("colonia").value = event.target.value
    }
    handlePaisChange(event) {
        document.getElementById("pais").value = event.target.value
    }
    handleCiudadChange(event) {
        document.getElementById("ciudad").value = event.target.value
    }
    handleNumeroIntChange(event) {
        document.getElementById("numeroInt").value = event.target.value
    }
    handleTelefonoChange(event) {
        document.getElementById("telefono").value = event.target.value
    }

    editLocal(event) {
        event.preventDefault();

        axios.post(
            KRAKEN_URL + 'locales/editLocal',
            {
                "estado": document.getElementById("estado").value,
                "localId": this.props.children[0].localId,
                "tipoPersona": document.getElementById("tipoPersona").value,
                "municipio": document.getElementById("municipio").value,
                "calle": document.getElementById("calle").value,
                "cp": document.getElementById("cp").value,
                "rfc": document.getElementById("rfc").value,
                "numeroExt": document.getElementById("numeroExt").value,
                "localAlias": document.getElementById("localAlias").value,
                "colonia": document.getElementById("colonia").value,
                "pais": document.getElementById("pais").value,
                "ciudad": document.getElementById("ciudad").value,
                "numeroInt": document.getElementById("numeroInt").value,
                "telefono": document.getElementById("telefono").value
            }
        ).then((res) => {
            if (res.data.result.success) {
                //alert(res.data.result.message)
                //window.location.reload(true)
                toast.success(res.data.result.message);
                setTimeout(function () {
                    window.location.reload(true);
                }, 3000);
            } else {
                //alert(res.data.result.message) 
                toast.error(res.data.result.message);
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    render() {          
        return (
            <section id="modalEditarLocal" className="background-body font-body text-center">
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Editar local</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="formLocales">
                            <fieldset>
                                <legend>Información</legend>
                                <Form.Row as={Col}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Línea razón social</Form.Label>
                                        <Form.Control type="text" disabled value={this.props.children[1]}></Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row as={Col}>
                                    <Form.Group as={Col}>
                                        <Form.Label>RFC</Form.Label>
                                        <Form.Control id="rfc" type="text" placeholder="Ingrese rfc"
                                            defaultValue={this.props.children[0].rfc}
                                            onChange={(event) => this.handleRfcChange(event)}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Tipo persona</Form.Label>
                                        <Form.Control id="tipoPersona" as="select"
                                            defaultValue={ this.props.children[0].tipoPersona} 
                                            onChange={(event) => this.handleTipoPersonaChange(event)}
                                        >
                                            <option>Elije una opción</option>
                                            <option value="F">Física</option>
                                            <option value="M">Moral</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control id="localAlias" type="text" placeholder="Ingrese nombre del local"
                                            defaultValue={this.props.children[0].localAlias}
                                            onChange={(event) => this.handleLocalAliasChange(event)}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Teléfono</Form.Label>
                                        <Form.Control id="telefono" type="text" placeholder="Ingresa número telefónico"
                                            defaultValue={this.props.children[0].telefono}
                                            onChange={(event) => this.handleTelefonoChange(event)}
                                        ></Form.Control>
                                    </Form.Group>
                                </Form.Row>
                            </fieldset>
                            <fieldset>
                                <legend>Dirección</legend>
                                <Form.Row as={Col}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Calle</Form.Label>
                                        <Form.Control id="calle" type="text" placeholder="Ingrese nombre de calle"
                                            defaultValue={this.props.children[0].calle}
                                            onChange={(event) => this.handleCalleChange(event)}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Número Exterior</Form.Label>
                                        <Form.Control id="numeroExt" type="text" placeholder="Ingrese número ext."
                                            defaultValue={this.props.children[0].numeroExt}
                                            onChange={(event) => this.handleNumeroExtChange(event)}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Número Interior</Form.Label>
                                        <Form.Control id="numeroInt" type="text" placeholder="Ingrese número int."
                                            defaultValue={this.props.children[0].numeroInt}
                                            onChange={(event) => this.handleNumeroIntChange(event)}
                                        ></Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row as={Col}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Colonia</Form.Label>
                                        <Form.Control id="colonia" type="text" placeholder="Ingrese colonia"
                                            defaultValue={this.props.children[0].colonia}
                                            onChange={(event) => this.handleColoniaChange(event)}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Municipio</Form.Label>
                                        <Form.Control id="municipio" type="text" placeholder="Ingrese municipio"
                                            defaultValue={this.props.children[0].municipio}
                                            onChange={(event) => this.handleMunicipioChange(event)}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Código postal</Form.Label>
                                        <Form.Control id="cp" type="text" placeholder="Ingrese cp"
                                            defaultValue={this.props.children[0].cp}
                                            onChange={(event) => this.handleCpChange(event)}
                                        ></Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row as={Col}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Estado</Form.Label>
                                        <Form.Control id="estado" type="text" placeholder="Ingrese estado"
                                            defaultValue={this.props.children[0].estado}
                                            onChange={(event) => this.handleEstadoChange(event)}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Ciudad</Form.Label>
                                        <Form.Control id="ciudad" type="text" placeholder="Ingrese ciudad"
                                            defaultValue={this.props.children[0].ciudad}
                                            onChange={(event) => this.handleCiudadChange(event)}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>País</Form.Label>
                                        <Form.Control id="pais" type="text" placeholder="Ingrese país"
                                            defaultValue={this.props.children[0].pais}
                                            onChange={(event) => this.handlePaisChange(event)}
                                        ></Form.Control>
                                    </Form.Group>
                                </Form.Row>
                            </fieldset>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center">
                        <ButtonGroup>
                            <button type="button" className="btn accionGuardar" onClick={(event) => this.editLocal(event)}>
                                <MaterialIcon icon="done" className="material-icons iconAlign"></MaterialIcon>
                                &nbsp;Editar
                                    </button>
                            <button type="button" onClick={this.props.onHide} className="btn accionCancelar">
                                <MaterialIcon icon="close" className="material-icons iconAlign"></MaterialIcon>
                                &nbsp;Cancelar
                                    </button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            </section>
        );
    }
}

export default ModalEditarLocal;