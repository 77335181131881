//const API_URL = 'https://portaladmin.kooben.it/api-kooben/';
const API_URL = 'https://dev.hixsa.net/api-kooben/'
//const API_URL = 'http://localhost:8080/api-kooben/'; 
//const KRAKEN_URL = 'https://portaladmin.kooben.it/api/admin/';
const KRAKEN_URL = 'https://dev.hixsa.net/Kraken/api/admin/';
//const KRAKEN_URL = 'http://localhost:8080/Kraken/api/admin/'; 
//const KRAKEN_AUTH = 'https://portaladmin.kooben.it/api/authKooben/';
const KRAKEN_AUTH = 'https://dev.hixsa.net/Kraken/api/authKooben'; 
//const KRAKEN_AUTH = 'http://localhost:8080/Kraken/api/authKooben'; 
const SERVICE_ID = 'S-Kd85j47D5FbCZL50QyA';

export { API_URL, KRAKEN_URL, KRAKEN_AUTH, SERVICE_ID };