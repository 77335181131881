import React, { PureComponent } from 'react';

//Diseño
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
//Estilos
import MaterialIcon from 'material-icons-react';
import "./ListaVentaDia.css"
//Utilerías
import {
    ResponsiveContainer, PieChart, Pie, Cell
} from 'recharts';

class GraficaCostos extends PureComponent {

    graficaCostos = () => {
        var cost = this.props.children;//toma los datos que le hereda ListaVentaDia
        let costos;
        const COLORS = ['#ee2737', '#58aeb7'];

        if (cost !== undefined && cost.length > 1) {//Si recibe más de un registro realiza suma general para obtener costo total y cálcular costo porcentual
            let totalVN = 0, totalCosto = 0, totalCostoP = 0, porcentajeCostoP = 0, totalVT = 0, utilidad = 0;

            cost.forEach(costo => { //realiza suma de todos los registros
                totalVN = totalVN + parseInt(costo.vneta);
                totalCosto = totalCosto + parseInt(costo.costo);
                totalVT = totalVT + parseInt(costo.vtotal);
            });

            totalCostoP = (totalCosto * totalVT) / totalVN; //Obtiene costo porcentual
            //porcentajeCosto = totalCosto === 0 || null ? 70 : 100 - utilidad;//100 -totalCostoP;//Si no hay registro de costo, por default será de 70%
            porcentajeCostoP = totalCosto === 0 || null ? 30 : totalCostoP;
            utilidad = porcentajeCostoP === 30 ? 70 : totalVT - totalCostoP;

            costos = [//Arreglo que recibe la gráfica con los datos a mostrar
                { name: 'Costo Total', value: porcentajeCostoP },
                { name: 'Utilidad', value: utilidad }
            ]
        } else if (cost !== undefined && cost.length === 1) { //Si recibe un solo registro, cálcula costo porcentual
            let costoPorcentual = 0, calPrctj = 0, utilidad = 0;;

            cost.forEach(costo => {
                calPrctj = Number(((costo.costo * costo.vtotal) / costo.vneta).toFixed(2)); //Calcula porcentaje de costo%
                //costoTotal = costo.costo === 0 || null ? 70 : 100 - calPrctj;
                costoPorcentual = costo.costo === 0 || null ? 30 : calPrctj;
                utilidad = costoPorcentual === 30 ? 70 : costo.vtotal - calPrctj;
            });

            costos = [//Arreglo que recibe la gráfica con los datos a mostrar
                { name: 'Costo Total', value: costoPorcentual },
                { name: 'Utilidad', value: utilidad }
            ]
        }
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({
            cx, cy, midAngle, innerRadius, outerRadius, percent, index,
        }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(1)}%`}
                </text>
            );
        };

        return costos !== undefined ?
            <div className="row" style={{ width: '100%', height: 200, margin: '0px' }}>
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            data={costos}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={95}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {costos.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
            : <PieChart></PieChart>
    }

    graficaVentaTotal = () => {
        let vta = this.props.children;//toma los datos que le hereda ListaVentaDia
        let gVentas;
        const COLORS = ['#f28d29', '#855fa5'];

        if (vta.length > 1) {//Si recibe más de un registro realiza suma general de ventas
            let totalVN = 0, totalViva = 0;

            vta.forEach(venta => {//Realiza suma por tipo de venta
                totalVN = totalVN + parseInt(venta.vneta);
                totalViva = totalViva + parseInt(venta.viva);
            });

            gVentas = [//Arreglo que recibe la gráfica con los datos a mostrar
                { name: 'Venta Neta', value: totalVN },
                { name: 'Impuestos', value: totalViva }
            ]
        } else if (vta !== undefined && vta.length === 1) {//Si recibe un solo registro, solo manda los datos de ventas
            console.log("vneta: ", vta[0].vneta, " viva: ", vta[0].viva);

            gVentas = [//Arreglo que recibe la gráfica con los datos a mostrar
                { name: 'Venta Neta', value: vta[0].vneta },
                { name: 'Impuestos', value: vta[0].viva }
            ]
        }

        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({
            cx, cy, midAngle, innerRadius, outerRadius, percent, index,
        }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(1)}%`}
                </text>
            );
        };
        return gVentas !== undefined ?
            <div className="row" style={{ width: '100%', height: 200, margin: '0px' }}>
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            data={gVentas}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={95}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {gVentas.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
            : <PieChart></PieChart>
    }

    graficaVentaBruta = () => {
        let vta = this.props.children;//toma los datos que le hereda ListaVentaDia        
        let gVentas;
        const COLORS = ['#855fa5', '#ee2737'];

        if (vta.length > 1) {//Si recibe más de un registro realiza suma general de ventas
            let totalVDesc = 0, totalVT = 0;

            vta.forEach(venta => {//Realiza suma por tipo de venta
                //totalVN = totalVN + parseInt(venta.vneta);
                totalVT = totalVT + parseInt(venta.vtotal);
                totalVDesc = totalVDesc + parseInt(venta.vdesc)
                //totalVB = totalVB + parseInt(venta.vbruta);
                console.log("vbruta: ", totalVDesc, " - ", totalVT);

            });

            gVentas = [//Arreglo que recibe la gráfica con los datos a mostrar
                // {name: 'Venta Neta', value: totalVN },
                { name: 'Venta Total', value: totalVT },
                { name: 'Descuentos', value: totalVDesc }
            ]
        } else if (vta !== undefined && vta.length === 1) {//Si recibe un solo registro, solo manda los datos de ventas
            console.log("vtotal: ", vta[0].vtotal, " vdesc: ", vta[0].vdesc);

            gVentas = [//Arreglo que recibe la gráfica con los datos a mostrar
                { name: 'Venta Neta', value: vta[0].vtotal },
                { name: 'Descuentos', value: vta[0].vdesc }
            ]
        }

        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({
            cx, cy, midAngle, innerRadius, outerRadius, percent, index,
        }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(1)}%`}
                </text>
            );
        };
        return gVentas !== undefined ?
            <div className="row" style={{ width: '100%', height: 200, margin: '0px' }}>
                <ResponsiveContainer>
                    <PieChart >
                        <Pie
                            data={gVentas}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={95}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {gVentas.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
            : <PieChart></PieChart>
    }

    render() {
        return (
            /*<CardDeck className="containerGraficas card-columns"> */
            <Row>
                <Col lg={4} md={6}>
                    <Card className="card-custome bordeCostos">
                        <Card.Header className="card-header-costos" style={{ color: "#FFFFF" }}>
                            <h5>Costo/Utilidad</h5>
                        </Card.Header>
                        <Card.Body>
                            {this.graficaCostos()}
                            <Form>
                                <Form.Label id="total">
                                    <MaterialIcon icon="stop" className="material-icons rojoCostos"></MaterialIcon>Costo
                                </Form.Label>&nbsp;
                                <Form.Label id="porcent">
                                    <MaterialIcon icon="stop" className="material-icons cyan"></MaterialIcon>Utilidad Ordinaria
                                </Form.Label>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} md={6}>
                    <Card className="card-custome bordeGraficaVentaTotal" style={{ color: "#FFFFF" }}>
                        <Card.Header className="card-header-g-ventaTotal">
                            <h5>Venta Total</h5>
                        </Card.Header>
                        <Card.Body>
                            {this.graficaVentaTotal()}
                            <Form>
                                <Form.Label id="total">
                                    <MaterialIcon icon="stop" className="material-icons amarilloVentas"></MaterialIcon>Venta Neta
                                </Form.Label>&nbsp;
                                <Form.Label id="porcent">
                                    <MaterialIcon icon="stop" className="material-icons morado"></MaterialIcon>Impuestos
                                </Form.Label>&nbsp;
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} md={6}>
                    <Card className="card-custome bordeGraficaVentaBruta" style={{ color: "#FFFFF" }}>
                        <Card.Header className="card-header-g-ventaBruta">
                            <h5>Venta Bruta</h5>
                        </Card.Header>
                        <Card.Body>
                            {this.graficaVentaBruta()}
                            <Form>
                                <Form.Label id="total">
                                    <MaterialIcon icon="stop" className="material-icons morado"></MaterialIcon>Venta Total
                                </Form.Label>&nbsp;
                                <Form.Label id="porcent">
                                    <MaterialIcon icon="stop" className="material-icons rojo"></MaterialIcon>Descuentos
                                </Form.Label>&nbsp;
                        </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default GraficaCostos;