//Librerías
import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux';
import { KRAKEN_URL } from '../../constants'

//Estilos
import MaterialIcon from 'material-icons-react'
import './ListadoLocales.css'

//Elementros bootstrap
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

//Componentes
import ModalAgregarLocal from './FormLocales'
import ModalEditarLocal from './ModalEditarLocal'
import ModalCuentaAcceso from './ModalCuentaAcceso'
import ModalConfirmDelete from './ModalConfirmDelete';
import { ToastContainer, toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

class ListadoLocales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jwt: this.props.user,
            linea: [],
            locales: [],
            local: [],
            tamanioPagina: 10,
            modalAddShow: false,
            modalEditShow: false,
            modalCuentaShow: false,
            modalDeleteShow: false,
            request: true,
            error: ''
        };
        this.handleSizePerPage = this.handleSizePerPage.bind(this);
    }
    componentDidMount() {
        let authKooben = this.state.jwt;
        let base64url = authKooben.split('.')[1];
        let decKooben = JSON.parse(window.atob(base64url));

        this.setState({
            id_linea: decKooben.lineaId
        });

        this.getListLocales(decKooben.lineaId);
        this.getLinea(decKooben.lineaId);
    }

    handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        this.setState({
            tamanioPagina: newSizePerPage
        });
        onSizePerPageChange(newSizePerPage, page);
    }

    componentDidUpdate(prepProps, prevState) {
        if (prevState.tamanioPagina !== this.state.tamanioPagina) {
            this.getListLocales();
        }
    }
    getLinea(lineaId) {
        axios.post(
            KRAKEN_URL + 'lineas/lineaId',
            {
                "lineaId": lineaId
            },
            {
                headers: { 'Authorization': 'Bearer ' + this.state.jwt }
            }
        ).then((res) => {
            if (res.data.result.success) {
                this.setState({
                    linea: res.data.linea.razonSocial,
                    request: false
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getListLocales(lineaId) {
        axios.post(
            KRAKEN_URL + 'locales/list',
            {
                "lineaId": lineaId
            },
            {
                headers: { 'Authorization': 'Bearer ' + this.state.jwt }
            }
        ).then((res) => {
            if (res.data.result.success) {
                this.setState({
                    locales: res.data.locales,
                    request: false
                })
            }
            this.mostrarListaLocales();
        }).catch((err) => {
            console.log(err);
        });
    }

    getLocal(localId, event) {
        const info = [];
        event.preventDefault();
        axios.post(
            KRAKEN_URL + 'locales/localid',
            {
                "localId": localId
            }
        ).then((res) => {

            if (res.data.result.success) {
                this.setState({
                    local: res.data.local,
                    modalEditShow: true
                });
            } else {
                toast.info(res.data.result.message);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    getCuenta(localId, event) {
        event.preventDefault();
        axios.post(KRAKEN_URL + 'locales/localid',
            {
                "localId": localId
            }
        ).then(res => {
            if (res.data.result.success) {
                this.setState({
                    local: res.data.local,
                    modalCuentaShow: true
                });
            } else {
                toast.info(res.data.result.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    confirmDelete(localId, e) {
        e.preventDefault();
        axios.post(KRAKEN_URL + 'locales/localid',
            {
                "localId": localId
            }
        ).then(res => {
            if (res.data.result.success) {
                this.setState({
                    local: res.data.local,
                    modalDeleteShow: true
                });
            } else {
                toast.info(res.data.result.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    mostrarListaLocales = () => {
        const local = this.state.locales;

        if (this.state.error) {
            return (
                <div className="background-body font-body text-center cardCentrado">
                    <Alert variant="danger">
                        <Alert.Heading>¡Ha ocurrido un error inesperado!</Alert.Heading>
                        <p>
                            {/* */}
                        </p>
                        <hr />
                        <p className="mb-0">
                            <Alert.Link href="../../src/componentes/LocalesEstatus/LocalEstatus.jsx">Volver a Inicio</Alert.Link>.
                        </p>
                    </Alert>
                </div>
            );
        } else {
            const columns = [{
                dataField: 'localAlias',
                text: 'Local'
            }, {
                dataField: 'rfc',
                text: 'RFC'
            }, {
                dataField: 'correo',
                text: 'Correo'
            }, {
                dataField: 'telefono',
                text: 'Teléfono'
            }, {
                dataField: 'esMatriz',
                text: 'Matriz',
                formatter: (cellContent, row) => {
                    return (
                        row.esMatriz === "1" ?
                            <MaterialIcon icon="domain" className="material-icons btn-matriz-back"></MaterialIcon>
                            : <MaterialIcon icon="domain" className="material-icons btn-nomatriz-back"></MaterialIcon>
                    );
                },
            }, {
                dataField: 'acciones',
                isDummyField: true,
                text: 'Acciones',
                formatter: (cellContent, row) => {
                    return (
                        <ButtonGroup id="button-group">
                            <Button variant="light" size="sm" data-toggle="tooltip" data-placement="bottom" title="Editar local" onClick={(event) => this.getLocal(row.localId, event)}><MaterialIcon icon="edit" className="btn-edit-local-back material-icons"></MaterialIcon></Button>
                            <Button variant="light" size="sm" data-toggle="tooltip" data-placement="bottom" title="Actualizar cuenta de acceso" onClick={(event) => this.getCuenta(row.localId, event)}><MaterialIcon icon="person" className="btn-edit-cuenta-local-back material-icons"></MaterialIcon></Button>
                            <Button variant="light" size="sm" data-toggle="tooltip" data-placement="bottom" title="Borrar" onClick={e => this.confirmDelete(row.localId, e)}><MaterialIcon icon="clear" className="btn-erase-local-back material-icons"></MaterialIcon></Button>
                        </ButtonGroup>
                    );
                },
            }];

            function indication() {
                return local.length ? "Cargando..." : "No hay registros que mostrar";
            };

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size} locales
                </span>
            );

            const options = {
                custom: true,
                totalSize: local.length,
                paginationTotalRenderer: customTotal
            };

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form>
                            <fieldset className="fielsetLocales">
                                <Form.Row>
                                    <div className="d-flex flex-row">
                                        <Form.Label column>Mostrar</Form.Label>
                                        <Col>
                                            <Form.Control size="sm" id="sizePerPage" as="select" onChange={(e) => this.handleSizePerPage(paginationProps, e.target.value)}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value={local.length}>Todos</option>
                                            </Form.Control>
                                        </Col>
                                    </div>
                                    <Col>
                                        <PaginationTotalStandalone {...paginationProps} />
                                    </Col>
                                    <div className="d-flex flex-row-reverse">
                                        <Col >
                                            <PaginationListStandalone {...paginationProps} />
                                        </Col>
                                        <Form.Label column>Página</Form.Label>
                                    </div>
                                </Form.Row>
                            </fieldset>
                        </Form>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                keyField='localId'
                                data={local}
                                columns={columns}
                                bordered={false}
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    render() {
        let modalAddClose = () => this.setState({ modalAddShow: false });
        let modalEditClose = () => this.setState({ modalEditShow: false });
        let modalCuentaClose = () => this.setState({ modalCuentaShow: false });
        let modalDeleteClose = () => this.setState({ modalDeleteShow: false });
        return (
            <section id="listaLocales" className="text-center">
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Container className="justify-content-center containerLocales" fluid="true">
                    <Card className="card-custom-listado" style={{ border: "2px solid rgba(88,174,183,1)" }}>
                        <Card.Header className="card-header-listado-locales">
                            <h5>Locales Registrados</h5>
                        </Card.Header>
                        <div id="btn-list">
                            <Button
                                size="lg"
                                className="m-2 btn-add-back"
                                variant="light"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Agregar nuevo local"
                                onClick={() => this.setState({ modalAddShow: true })}
                            ><MaterialIcon icon="add" className="material-icons md-36"></MaterialIcon>
                            </Button>
                            <ModalAgregarLocal show={this.state.modalAddShow} onHide={modalAddClose}>{this.state.id_linea}{this.state.linea}</ModalAgregarLocal>
                            <ModalEditarLocal show={this.state.modalEditShow} onHide={modalEditClose}>{this.state.local}{this.state.linea}</ModalEditarLocal>
                            <ModalCuentaAcceso show={this.state.modalCuentaShow} onHide={modalCuentaClose}>{this.state.local}</ModalCuentaAcceso>
                            <ModalConfirmDelete show={this.state.modalDeleteShow} onHide={modalDeleteClose}>{this.state.local}</ModalConfirmDelete>
                        </div>
                        <Card.Body>
                            {this.state.request ? "Cargando..." : this.mostrarListaLocales()}
                        </Card.Body>
                    </Card>
                </Container>
            </section>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedListadoLocales = connect(mapStateToProps)(ListadoLocales);
export { connectedListadoLocales as ListadoLocales }